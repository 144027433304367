import React from 'react';
import { inject, observer } from 'mobx-react';
import Switch from 'react-switch';
import moment from 'moment';

import Form from '../../../components/ui/Form';
import CalendarDropdown from '../../../components/ui/CalendarDropdown';
import Error from '../../../components/ui/Error';
import Button from '../../../components/ui/Button';
import BetaModal from '../../../components/ui/Modal';


import { __ } from '../../../i18n';

const styles = {
  label: {
    fontWeight: 400,
    fontSize: '16px'
  },
  block: {
    marginBottom: '16px'
  }
};

const CONTRACT_TYPE_WITHOUT_INTEGRATION = [
  { text: __('Principal'), value: 'INTEGRATION' },
  { text: __('Additional'), value: 'ADDITIONAL' }
];

const CONTRACT_TYPE_WITH_INTEGRATION = [
  { text: __('Additional'), value: 'ADDITIONAL' }
];

const date = new Date();

@inject('store')
@observer
export default class ContractForm extends Form {
  defaultValues = {
    isActive: true,
    type: null,
    link: null,
    contractDate: new Date(date.getTime()),
    fee: 0.00,
    comments: null,
    cancellationReasonId: null,
    canceledAt: null
  }

  rules = {
    type: {
      rule: 'required',
      message: __('Contract type cannot be empty')
    }
    /* link: {
      rule: 'required',
      message: __('Contract link cannot be empty')
    } */
  }

  renderCancelFields = (lang) => {
    const { values, errors } = this.state;
    const { cancellationReasonsType } = this.props;
    const canceledAt = values.canceledAt ? moment(values.canceledAt).add(3, 'hours'): new Date();

    return (
      <div>
        <div style={styles.block}>
          <CalendarDropdown
            name="cancellationDate"
            label={__('Cancellation Date')}
            minDate={moment().subtract(5, 'years')}
            labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
            inputStyle={{ display: 'flex', flex: 1, flexDirection: 'column' }}
            error={errors && errors.canceledAt}
            lang={lang}
            value={canceledAt._d}
            setValue={value => this.setValue('canceledAt', value)}
          />
        </div>
        <div style={styles.block}>
          <span style={styles.label}>{`${__('Reason for Cancellation')}*`}</span>
          <div style={{ marginTop: '12px' }}>
            <Form.Dropdown
              className={'form-dropdown'}
              style={{ margin: '0 !important', borderColor: errors && errors.cancellationReasonId ? '#BF2600' : 'rgba(34,36,38,.15)' }}
              name="cancellationReasonId"
              icon={'chevron down'}
              compact
              fluid
              selection
              placeholder={__('Select')}
              value={values.cancellationReasonId}
              options={cancellationReasonsType.map(reason => ({ id: reason.id, text: reason.name, value: reason.id }))}
              onChange={(e, { value }) => this.setValue('cancellationReasonId', value)}
            />
            <Error text={errors && errors.cancellationReasonId} style={{ marginTop: '-12px' }} />
          </div>
        </div>
      </div>
    );
  }

  handleSwitch = (checked) => {
    if (!checked) {
      this.rules.cancellationReasonId = {
        rule: 'required', message: __('Cancellation reason cannot be empty')
      };
    } else {
      delete this.rules.cancellationReasonId;
    }

    this.setValue('isActive', checked);
  };

  checkCancelContract = (e, event) => {
    const { values } = this.state;
    const { edit } = this.props;
    if (!values.isPrevCanceled && !values.isActive && edit) {
      this.props.store.appends.push(
        <BetaModal
          id="ErrorModal"
          onClose={() => this.props.store.appends.pop()}
          actions={[
            <Button
              data-action="ok"
              round
              red
              text={__('Yes, I\'m sure')}
              onClick={() => { this.props.store.appends.pop(); this.handleSubmit(e, event, { /* errorsModal */ }); }}
            />
          ]}
          header={__('The contract will be canceled')}
          content={
            <div>
              <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{__('Are you sure you want to inactivate this contract? By doing this you will be canceling the integration and all its modules.')}</span>
            </div>
          }
        />
      );
    } else {
      this.handleSubmit(e, event, { /* errorsModal */ });
    }
  }

  render() {
    const { values, errors } = this.state;
    const { cancelButton, submitButton, submitButtonIcon, loading, hasIntegrationContract } = this.props;
    const lang = this.props.store.app.locale && this.props.store.app.locale.substring(0, 2) === 'pt' ? 'pt-BR' : 'en';
    const contractDate = moment(values.contractDate).add(3, 'hours');

    const actionButtonsLeft = [<Button
      data-action="cancel"
      round
      transparent
      floated="left"
      text={cancelButton.text}
      disabled={loading}
      onClick={() => (this.props.onClose ? this.props.onClose : this.props.router.goBack())}
    />];
    const actionButtonsRight = [<Button
      data-action="submit"
      round
      icon={submitButtonIcon && { name: submitButtonIcon }}
      text={submitButton.text}
      loading={loading}
      onClick={(e, event) => this.checkCancelContract(e, event)}
    />];
    return (
      <Form
        id="ContractForm"
        {...this.props}
        onSubmit={(e, event) => this.handleSubmit(e, event, { /* errorsModal */ })}
        paddingHeader={this.isMobile()}
        actionButtonsLeft={!this.isMobile() ? actionButtonsLeft : null}
        actionButtonsRight={!this.isMobile() ? actionButtonsRight : null}
        header={this.isMobile() ? {
          title: '',
          onClose: () => (this.props.onClose ? this.props.onClose : this.props.router.goBack()),
          invertCloseButton: true,
          headerItem: <Button
            data-action="submit"
            round
            loading={loading}
            text={submitButton.text}
            onClick={(e, event) => this.handleSubmit(e, event, { /* errorsModal */ })}
            style={{ height: '40px', width: '100px', justifyContent: 'center', alignItems: 'center', display: 'flex' }}
          />
        } : null}

      >
        <div style={{ marginBottom: '1rem' }}>
          <div style={styles.block}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Switch
                checked={values.isActive}
                onChange={this.handleSwitch}
                onColor="#084FFF"
                onHandleColor="#FFFFFF"
                offColor="#E7E7E7"
                handleDiameter={29}
                uncheckedIcon={false}
                checkedIcon={false}
                height={36}
                width={67}
              />
              <span style={{ marginLeft: '7px', fontSize: '16px' }}>{values.isActive ? __('Active Contract') : __('Inactive Contract')}</span>
            </div>
          </div>
          {!values.isActive && this.renderCancelFields(lang)}
          <div style={styles.block}>
            <span style={styles.label}>{`${__('Contract')}*`}</span>
            <div style={{ marginTop: '12px' }}>
              <Form.Dropdown
                className={'form-dropdown'}
                style={{ margin: '0 !important', borderColor: errors && errors.type ? '#BF2600' : 'rgba(34,36,38,.15)' }}
                name="type"
                icon={'chevron down'}
                compact
                fluid
                selection
                placeholder={__('Select')}
                value={values.type}
                options={hasIntegrationContract ? CONTRACT_TYPE_WITH_INTEGRATION : CONTRACT_TYPE_WITHOUT_INTEGRATION}
                onChange={(e, { value }) => this.setValue('type', value)}
              />
              <Error text={errors && errors.type} style={{ marginTop: '-12px' }} />
            </div>
          </div>
          <div style={styles.block}>
            <Form.Input
              name="link"
              placeholder={__('Link')}
              labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
              icon={{ before: { name: 'link' } }}
              error={errors && errors.link}
              red={errors && errors.link}
              value={values.link}
              onChange={e => this.onTextInputChange(e, { name: 'link', value: e.target.value })}
            />
          </div>
          <div style={styles.block}>
            <CalendarDropdown
              name="contractDate"
              label={__('Contract Date')}
              minDate={moment().subtract(5, 'years')}
              labelStyle={{ fontSize: '16px', marginBottom: '12px' }}
              inputStyle={{ display: 'flex', flex: 1, flexDirection: 'column' }}
              error={errors && errors.contractDate}
              lang={lang}
              value={contractDate._d}
              setValue={value => this.setValue('contractDate', value)}
            />
          </div>
          <div style={styles.block}>
            <span style={styles.label}>{__('Tax')}</span>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <div style={{ display: 'flex', alignItems: 'center', flex: 1, height: '84px' }}>
                <Form.Input
                  name="fee"
                  type="currency"
                  lang={lang}
                  style={{ display: 'flex', flex: 1 }}
                  value={values.fee}
                  onChange={e => this.onTextInputChange(e, { name: 'fee', value: e.target.value })}
                />
              </div>
            </div>
          </div>
          <div style={styles.block}>
            <Form.TextArea
              label={__('Comments')}
              cssTags={'big-height'}
              placeholder={__('Write your comments here...')}
              name="comments"
              value={values.comments}
              onChange={e =>
                this.onTextInputChange(e, { name: 'comments', value: e.target.value, maxDigits: 255 })
              }
            />
          </div>
        </div>
      </Form>
    );
  }
}
